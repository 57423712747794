<template>
    <van-popup v-model="isCityShow" @click-overlay="close" position="bottom" safe-area-inset-bottom class="pop-item">
        <div class="buy-title f32 fw6">
            选择卡城市
            <van-icon name="cross" class="buy-close f36" @click="close" />
        </div>
        <div class="cbox">
            <div class="city-list">
                <div v-for="(item, index) in cityList" :key="index"
                    :class="['city-model', 'row-between-center', curCityId === item.cityId ? 'active fw6' : '']"
                    @click="chooseCity(item)">
                    <p>{{ item.name }}</p>
                </div>
            </div>
        </div>

        <div>
            <p class="p1 f22">选择指定城市卡，选择后无法修改、退换。卡仅限所选定的城市使用。</p>
            <div :class="['next', 'fw6', curCityId ? '' : 'grey']" @click="setCity">确认领取</div>
        </div>

    </van-popup>
</template>
<script>
export default {
    props: {
        defaultCityId: {
            type: String
        }
    },
    data() {
        return {
            curCityId: '',
            isCityShow: false,
            cityList: [{ name: '福州', cityId: '3' }, { name: '厦门', cityId: '036652995ac5451fbb6791c3491e1c2d' }, { name: '广州', cityId: '818447834079563776' }]
        }
    },
    methods: {
        close() {
            this.isCityShow = false;
        },
        chooseCity(item) {
            this.curCityId = item.cityId
        },
        setCity(){
            this.$emit('setCity',this.curCityId);
            this.close();
        },
        getCityList() {
            this.$axios.post(`${this.baseURLApp}/scheduleLesson/cityList`, { userId: this.userId }).then(res => {
                const cityList = res.data.map(d => {
                    const model = {
                        text: d.areaName,
                        value: d.cityId
                    }
                    if (this.cityId == d.cityId) {
                        this.selectCityItem = model;
                    }
                    return model
                });
                this.cityList = cityList;
                console.log(this.cityList);
                // let item = this.cityList.find(r => {
                //     return r.cityId === this.currentCityId
                // })
                // if (item) {
                //     this.cityName = item.areaName
                //     this.currentCityId = item.cityId
                // }
            })
        },
    }
}
</script>
<style lang="less" scoped>
.pop-item {
    border-radius: 24px 24px 0 0;
    color: #242831;
    background-color: #fff;

    .buy-title {
        border-bottom: 1px solid #EEEEEE;
        height: 97px;
        line-height: 97px;
        padding: 0 32px;
        text-align: center;
        position: relative;
    }

    .buy-close {
        position: absolute;
        right: 32px;
        top: 50%;
        transform: translateY(-50%);

    }

    .cbox {
        padding: 37px 32px 0;
        border-bottom: 1px solid #eee
    }

    .p1 {
        margin: 34px 32px 0;
        color: #68413C;
    }

    .prize-des {
        padding: 0 32px;
        margin: 32px 0 124px;

        .pic {
            width: 160px;
            margin: 0 40px 0 0;
        }

        .p2 {
            margin: 24px 0 0;
        }
    }

    .next {
        line-height: 96px;
        background: #FFDE00;
        border-radius: 8px;
        margin: 16px 32px 0;
        text-align: center;
        margin-bottom: 34px;

        &.grey {
            filter: grayscale(1);
        }
    }

    .city-list {
        padding: 0 0 32px;

        .city-model {
            margin-top: 24px;
            height: 128px;
            background: #F5F5F5;
            border-radius: 8px;
            padding: 0 32px;

            &.disabled {
                color: #9AA1A9;
            }

            &.active {
                border: 4px solid #242831;

                .checkbox {
                    width: 48px;
                    height: 48px;
                    background: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/spring2023/check.png);
                    background-size: 100% 100%;
                    border: none;
                    margin: 0;
                }
            }

            .checkbox {
                width: 32px;
                height: 32px;
                border: 1px solid #DDD;
                border-radius: 50%;
                margin-right: 8px;
            }

            .has-buy {
                width: 102px;
            }
        }
    }
}
</style>