<!-- 2024/04/25  $refs.xxx.init 初始化  $refs.xxx.stop 暂停  $refs.xxx.keep 继续 -->
<template>
    <div class="com-barrage">
        <div v-for="item in data" class="barrage-line">
            <div v-for="citem in item.children" class="barrage-model" :class="[citem.theme, citem.class]"
                :ref="'barrage' + citem.id" :key="citem.id"
                :style="{ transform: 'translate3d(' + citem.left + 'px,0,0)' }">
                {{ citem.text }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "WXG_barrage",
    props: {},
    data() {
        return {
            data: [],
            boxWidth: 0,
            status: undefined
        };
    },
    created() { },
    mounted() {
        this.boxWidth = this.$el.clientWidth;
        this.boxHeight = this.$el.clientHeight;
        // this.init();
    },
    beforeDestroy() {
        this.status = 'stop'
    },
    methods: {
        keep() {
            if (this.status == 'stop') {
                this.aniStart();
            }
        },
        stop() {
            this.status = 'stop'
        },
        async init(option) {
            this.status = 'stop';
            this.options = {
                lineNum: 4,//默认3行
                barrageData: [],
                defaultFull: true,//默认满屏
                distance: 20,//距离默认30
                ...option
            }
            const insertData = [];
            for (let i = 0; i < this.options.lineNum; i++) {
                insertData.push({ children: [] })
            }
            this.data = insertData;
            for (let i = 0; i < this.options.barrageData.length; i++) {
                await this.addDom(this.options.barrageData[i]);
            }
            setTimeout(() => {
                this.aniStart();
            }, 2000)

            // console.log(this.data);
        },
        aniStart() {
            // return;
            this.status = 'go';
            for (let i = 0; i < this.data.length; i++) {
                const d = this.data[i].children;
                for (let j = 0; j < d.length; j++) {
                    const model = d[j]
                    if (model.state == 'wait') {
                        this.aniDom(i, model);
                        break;
                    }
                }
            }
        },
        getLeft(children) {
            if (this.options.defaultFull) {
                let left = 0;
                for (let i = 0; i < children.length; i++) {
                    const model = children[i];
                    left += model.width + this.options.distance;
                }
                left += this.options.distance;
                if (left > this.boxWidth) {
                    left = this.boxWidth;
                }
                return left;
            } else {
                return this.boxWidth;
            }
        },
        async addDom(item) {
            return new Promise(async resolve => {
                if(!item.text){return resolve();}
                // setTimeout(async () => {
                const positionIndex = this.getIndex();
                const id = Math.random().toString(16).slice(2);
                const children = this.data[positionIndex].children;
                const obj = {
                    id,
                    state: 'wait',
                    width: 0,
                    text: item.text,
                    left: this.getLeft(children),
                    class: item.class,
                    theme: (children[children.length - 1] || {}).theme == 'theme-black' ? undefined : 'theme-black'
                }
                children.push(obj);
                await this.$nextTick();
                const dom = this.$refs['barrage' + id][0];
                obj.width = dom.clientWidth;
                resolve();
                // })
            })
        },
        async fastData(item) {
            // const a=[1,2,3,4,5];
            // a.splice(a.length,0,'obj')
            // console.log(a);
            const id = Math.random().toString(16).slice(2);
            const obj = {
                id,
                state: 'wait',
                width: 0,
                text: item.text,
                left: this.boxWidth,
                class: item.class
            }
            const index = parseInt(Math.random() * this.options.lineNum);
            const children = this.data[index].children;
            const insertIndex = children.findIndex(d => {
                return d.state == 'wait'
            })

            children.splice(insertIndex == -1 ? children.length : insertIndex, 0, obj);
            console.log(JSON.parse(JSON.stringify(this.data[0])));
            await this.$nextTick();
            const dom = this.$refs['barrage' + id][0];
            obj.width = dom.clientWidth;
        },
        getTheme(dataIndex, obj) {
            const children = this.data[dataIndex].children;
            var index = children.indexOf(obj);
            return (children[index - 1] || {}).theme == 'theme-black' ? undefined : 'theme-black'
        },
        aniDom(dataIndex, obj) {
            let left = obj.left || this.boxWidth;
            let hasDoNext = false;
            const dom = this.$refs['barrage' + obj.id][0];
            if (!dom) {
                return;
            }
            obj.theme = this.getTheme(dataIndex, obj);
            const fn = async () => {
                if (this.status == 'stop') {
                    obj.state = 'wait';
                    cancelAnimationFrame(timer);
                    hasDoNext = undefined;
                    timer = null;
                    return;
                }
                const children = this.data[dataIndex].children;
                if (left > -dom.clientWidth) {//还在屏幕
                    let state = 'in';
                    if (left < this.boxWidth - obj.width - this.options.distance) {//下一趟可出发
                        state = 'cannext';
                        var index = children.indexOf(obj);
                        if (children.length > index + 1 && children[index + 1].state == 'wait' && !hasDoNext) {
                            hasDoNext = true;
                            this.aniDom(dataIndex, children[index + 1]);
                        }
                    }
                    obj.state = state;
                    left--;
                    obj.left = left;
                    requestAnimationFrame(fn);
                } else {
                    obj.state = 'wait';
                    obj.left = this.boxWidth;
                    cancelAnimationFrame(timer);
                    var index = children.indexOf(obj);
                    children.splice(index, 1);
                    hasDoNext = undefined;
                    timer = null;
                    children.push(obj);
                    if (children.length == 1) {//只有自己
                        this.aniDom(dataIndex, obj);
                    }
                }
            }
            let timer = requestAnimationFrame(fn);
        },
        getIndex() {
            let index = 0;
            let min = 999999;
            for (let i = this.data.length - 1; i >= 0; i--) {
                let total = 0;
                const model = this.data[i].children;
                for (let j = 0; j < model.length; j++) {
                    total += model[j].width;
                }
                // console.log(total);
                if (total <= min) {
                    min = total;
                    index = i;
                }
            }
            return index;
        }
    },
};
</script>
<style lang="less" scoped>
.com-barrage {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;

    div {
        box-sizing: border-box;
    }

    .barrage-line {
        height: 60px;
        margin: 28px 0;
    }

    .barrage-model {
        position: absolute;
        font-size: 26px;
        color: rgb(255, 204, 0);
        background: #fff;
        line-height: 60px;
        height: 60px;
        min-width: 20px;
        border-radius: 10px;
        font-size: 26px;
        color: #004C7D;
        padding: 0 18px;
        // transform: translateX(100vw);

        &.theme-black {
            opacity: 0.7;
        }

        &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 18px;
            width: 0;
            height: 0;
            margin-top: -10px;
            overflow: hidden;
            font-size: 0;
            /*是因为, 虽然宽高度为0, 但在IE6下会具有默认的 */
            line-height: 0;
            /* 字体大小和行高, 导致盒子呈现被撑开的长矩形 */
            border-width: 10px;
            border-style: solid;
            border-color: transparent transparent transparent #fff;
            border-radius: 6px;
        }
    }
}
</style>