<template>
    <div class="com_mission">
        <div class="title"><img
                src="https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/88buy-preheat-2024/mission/title.png" /></div>
        <div class="barrage">
            <Barrage ref="refBarrage" />
        </div>
        <div class="send-sub" @click="content='',showFlag = true">
            <img src="https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/88buy-preheat-2024/mission/sub.png" class="">
        </div>
        <div class="bg1">
            <div class="gbox" :class="{ 'in-xscroll': inXScroll }">
                <div class="pd" :style="{
                    '--endMove': '-' + endMove + 'px'
                }" ref="refScrollX">
                    <div class="trans-box" :class="ani">
                        <div class="gmodel" v-for="item in prizeList">
                            <div class="g1">
                                <img :src="item.rewardImg" v-if="item.rewardImg" />
                            </div>
                            <div class="g2">
                                <div class="pname ellipsis-2">{{ item.rewardName }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="get"></div>
            </div>
            <div class="sub-draw" @click="startANI"> </div>
            <div class="info-line">
                <div class="il-model" @click="openDrawFrom">抽奖机会剩 <span class="BebasNeueBold nf">{{ numOfLottery }}</span> 次
                </div>
                <div class="il-model" @click="openMyprize">已获得奖品 <span class="BebasNeueBold nf">{{ rewardNum }}</span> 个
                </div>
            </div>
        </div>
        <div class="bg2"></div>
        <div class="bg3">
            <div class="mission-list">
                <div class="mission-model" v-for="(item, key) in missionIntoJson">
                    <div class="mission-logo">
                        <img :src="item.src" />
                    </div>
                    <div class="mission-info">
                        <div class="mission-name">{{ item.name }}</div>
                        <div class="mission-num">
                            {{ item.label }}
                            <span>（{{ item.num }}/{{ item.total }}）</span>
                        </div>
                    </div>
                    <div v-if="status" class="mission-sub dis">已结束</div>
                    <div v-else class="mission-sub" :class="{ dis: item.completionStatus == 1 }" @click="doMission(key)">
                        {{ item.completionStatus == 1 ? '已完成' : item.buttonText }}</div>
                </div>
            </div>
        </div>
        <van-popup v-model="showFlag" position="center" class="popsend" :close-on-click-overlay="false">
            <div class="top-jb"></div>
            <div class="send-head">
                立Flag·赢抽奖机会
            </div>
            <div class="textarea">
                <textarea placeholder="请输入祝福内容..." v-model.trim="content" maxlength="500"></textarea>
                <div class="content-len">{{ getLen() }}/20</div>
            </div>
            <div class="say-label">他们在说</div>
            <div class="say-box">
                <div v-for="item in chooseSayList" class="choose-model" @click="content = item">{{ item }}</div>
            </div>
            <div class="send-subline" @click="addData">
                立Flag
            </div>
            <div class="popsend-close" @click="showFlag = false">
                <img src="https://img.chaolu.com.cn/ACT/anniversary-2024/index/close-icon.png" />
            </div>
        </van-popup>
        <van-popup v-model="showDrawFrom" position="bottom" safe-area-inset-bottom class="popdrawfrom">
            <div class="pop-title">
                <span class="ts">抽奖机会明细</span>
                <van-icon name="cross" class="pop-close" color="#fff" @click="showDrawFrom = false" />
            </div>
            <div class="draw-total">剩余抽奖机会：{{ numOfLottery }}</div>
            <div class="drawfrom-box">
                <template v-if="hasData">
                    <van-list v-model="loading" :finished="finished" @load="getLotteryDetail" :immediate-check="false"
                        :offset="50" finished-text="没有更多了">
                        <div class="drawfrom-model" v-for="item in drawFromList">
                            <div class="drawfrom-way">
                                <div class="drawfrom-way-1">{{ item.title }}</div>
                                <div class="drawfrom-way-2">
                                    <span v-if="item.num > 0">+{{ item.num }}</span>
                                    <span v-else class="color-red">{{ item.num }}</span>
                                </div>
                            </div>
                            <div class="drawfrom-time">{{ item.obtainDate }}</div>
                        </div>
                    </van-list>
                </template>
                <div class="nulldata" v-else>
                    <img src="https://img.chaolu.com.cn/ACT/anniversary-2024/index/null.png" />
                    <div class="nulltip">您还未获得抽奖机会哦</div>
                </div>
            </div>
        </van-popup>
        <van-popup v-model="showNoNum" position="center" class="popnonum" :close-on-click-overlay="false">
            <div class="line1">您没有抽奖机会啦!</div>
            <div class="line2">完成任务即可获得更多抽奖机会</div>
            <div class="num-subline" @click="showNoNum = false">
                我知道了
            </div>
        </van-popup>
        <van-popup v-model="showPrize" position="center" class="popprize" :close-on-click-overlay="false">
            <div class="jackpot-name ellipsis-2">
                {{ prizeItem.rewardName }}
            </div>
            <div class="jackpot-pic">
                <img :src="prizeItem.rewardImg" class="" />
            </div>

            <div class="jackpot-label" v-if="prizeItem.sendType == 'AUTO'">系统已发放至您的账户</div>
            <div class="jackpot-label" v-if="prizeItem.sendType == 'COLLECT_IN_STORE'">请选择领取门店，奖品请前往门店领取</div>
            <div class="jackpot-label" v-if="prizeItem.sendType == 'CHOOSE_CITY'">请选择您所在的城市兑换</div>
            <!-- 请选择领取门店，奖品请前往门店领取 -->
            <!-- 请选择您所在的城市兑换 -->

            <div class="jackpot-subline">
                <div class="jackpot-sub-1 jackpot-sub-model" @click="resetPrize" v-if="prizeItem.sendType == 'AUTO'">我知道了
                </div>
                <div class="jackpot-sub-1 jackpot-sub-model" @click="dh" v-if="prizeItem.sendType == 'CHOOSE_CITY'">去兑换</div>
                <div class="jackpot-sub-1 jackpot-sub-model" @click="lq" v-if="prizeItem.sendType == 'COLLECT_IN_STORE'">去领取
                </div>
                <div class="jackpot-sub-2 jackpot-sub-model" @click="againPrize">再抽一次</div>
            </div>
            <div class="popprize-close" @click="resetPrize">
                <img src="https://img.chaolu.com.cn/ACT/anniversary-2024/index/close-icon.png" />
            </div>
        </van-popup>
        <MyPrize ref="refMyPrize" />
        <SelectCity ref="refSelectCity" @setCity="setCity" />
    </div>
</template>
<script>
import Barrage from '@/components/app/barrage.vue';
import userMixin from '@/mixin/userMixin';
import { getEmojiArray } from '@/lib/emoji';
import MyPrize from './myPrize.vue';
import SelectCity from './selectCity.vue';

function checkIsXSS(v) {/*xss校验函数，返回值：true 表示存在xss漏洞，false：不存在*/
    if (typeof v == "string" && v) {
        var exp = new RegExp("\\b(document|onload|eval|script|img|svg|onerror|javascript|alert)\\b");
        return exp.test(v);
    }
    return false;
}
export default {
    components: {
        Barrage,
        MyPrize,
        SelectCity
    },
    mixins: [userMixin],
    data() {
        return {
            showPrize: false,
            showNoNum: false,
            showFlag: false,
            showDrawFrom: false,
            numOfLottery: 0,
            rewardNum: 0,
            inXScroll: false,
            prizeItem: {},
            endMove: 0,
            ani: '',
            pageNum: 1,
            prizeList: [],
            finished: false,
            loading: false,
            hasData: true,
            content: '',
            status:0,//1全部结束，2部分结束。。。
            missionIntoJson: {
                1: {
                    src: 'https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/88buy-preheat-2024/mission/ms1.png',
                    name: '写下你的运动FLAG',
                    label: '每日立flag可得1次',
                    num: 0,
                    total: 0,
                    completionStatus: 0,
                    buttonText: '去完成'
                },
                2: {
                    src: 'https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/88buy-preheat-2024/mission/ms2.png',
                    name: '每日分享活动',
                    label: '每日最多可得1次',
                    num: 0,
                    total: 0,
                    completionStatus: 0,
                    buttonText: '去分享'
                },
                3: {
                    src: 'https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/88buy-preheat-2024/mission/ms3.png',
                    name: '集超鹿徽章',
                    label: '每解锁1枚可得1次',
                    num: 0,
                    total: 0,
                    completionStatus: 0,
                    buttonText: '去完成'
                },
                4: {
                    src: 'https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/88buy-preheat-2024/mission/ms4.png',
                    name: '合成全民健身季奖牌',
                    label: '合成奖牌可得5次',
                    num: 0,
                    total: 0,
                    completionStatus: 0,
                    buttonText: '去完成'
                }
            },
            chooseSayList: ['管住嘴，迈开腿🦵', '逢练必成✌', '🤣想要的都有，狂吃的不胖', '🙏泼天富贵轮到我', '卧推重量暴涨🫡'],
            drawFromList: []
        }
    },
    created() {
        setTimeout(() => {
            this.$getAllInfo(['userId', 'cityId']).then(() => {
                this.init();
            })
        }, 0)
    },
    methods: {
        init() {
            this.getData();
            this.getDrawNum();
        },
        getData() {
            return this.$axios.post(this.baseURLApp + "/anniversary/taskGift/queryMainPageInfo", {
                hasWx: true,
                userId: this.userId,
            }).then((res) => {
                console.log(res);
                const d = res.data;
                this.status = d.endOfActivity;
                if (d.endOfActivity == 1) {
                    this.$dialog.alert({
                        message: ' <b>很抱歉，该活动已经结束！<br/>去看看超鹿其他的活动吧</b>',
                        confirmButtonText: '我知道了',
                        className: 'dialog',
                        overlayStyle: { backgroundColor: 'rgba(0,0,0,1)' }
                    }).then(() => {
                        if (this.appTypeStr === 'mini') {
                            wx.miniProgram.switchTab({
                                url: `/pages/home/index`
                            })
                        } else {
                            this.newAppBack()
                        }
                    })
                    return;
                }
                this.basePrize = d.rewardList;
                this.prizeList = this.basePrize;
                if (d.flags && d.flags.length && !this.hasInit) {
                    this.hasInit = true;
                    this.$refs.refBarrage.init({
                        barrageData: d.flags.map(d => {
                            return {
                                text: d.flag,
                                class: d.type === 0 ? 'color-my' : undefined
                            }
                        })
                    });
                }
                const ms = d.taskCompletionStatus || [];
                for (let i = 0; i < ms.length; i++) {
                    this.missionIntoJson[ms[i].type] = { ...this.missionIntoJson[ms[i].type], ...ms[i] };
                }

                console.log(this.missionIntoJson);
            })
        },
        openMyprize() {
            this.$refs.refMyPrize.show()
        },
        dh() {
            this.resetPrize();
            this.$refs.refSelectCity.isCityShow = true;
        },
        lq() {
            this.$router.push({
                path: '/superdeer-activity/88buy-preheat-2024/receive', query: {
                    participateId: this.prizeItem.participateId
                }
            })
        },
        doMission(type) {
            this.showMission = false;
            type = parseInt(type);
            switch (type) {
                case 1: {
                    this.countPoint('403', '403', '40026');
                    this.showFlag = true;
                    break;
                }
                case 2: {
                    this.countPoint('403', '403', '40027');
                    this.$parent.showShareFun();
                    break;
                }
                case 3: {
                    this.countPoint('403', '403', '40028');
                    this.$parent.scrollDownFun();
                    break;
                }
                case 4: {
                    this.countPoint('403', '403', '40029');
                    this.$parent.scrollDownFun();
                    break;
                }
                default:
                    break;
            }
        },
        async setCity(cityId) {
            const res = await this.$axios.post(this.baseURLApp + "/anniversary/taskGift/awarding", {
                hasWx: true,
                userId: this.userId,
                cityId,
                participateId: this.prizeItem.participateId
            })
            this.$toast('兑换成功');
        },
        resetPrize() {
            this.showPrize = false;
            this.ani = '';
            this.inXScroll = false;
            const dom = this.$refs.refScrollX;
            dom.scrollLeft = 0;
            this.prizeList = this.basePrize;
        },
        againPrize() {
            this.resetPrize();
            this.$nextTick(() => {
                this.startANI();
            })
        },
        async startANI() {
            if (this.inGame) { return }
            if (!this.basePrize || !this.basePrize.length) {
                this.$toast('奖品池是空的')
                return;
            }
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType: 'spinner',
            })
            await this.getDrawNum();
            if (this.numOfLottery < 1) {
                this.$toast.clear();
                this.showNoNum = true;
                return;
            }
            
            this.inGame = true;
            const res = await this.$axios.post(this.baseURLApp + "/anniversary/taskGift/play", {
                hasWx: true,
                userId: this.userId,
            })
            const d = res.data;
            // const d = {
            //     rewardId: "1261340107672125440",
            //     rewardImg: "http://img.chaolu.com.cn/sixReward/sj.png",
            //     rewardName: "48元团课券"
            // }
            this.$toast.clear();
            if (!d.rewardId) {
                this.$toast('抽奖数据异常')
                return;
            }
            let index = this.basePrize.findIndex(item => {
                return item.rewardId == d.rewardId
            })
            if (index < 0) {
                this.$toast('奖品数据异常')
                return;
            }
            this.prizeItem = d;
            const dom = this.$refs.refScrollX;
            dom.scrollLeft = 0;
            this.inXScroll = true;
            const append = [...this.basePrize].slice(0, 3);
            const list = [];
            let co = 0;
            const len = this.basePrize.length;
            while (co < 30) {
                list.push(...this.basePrize);
                co += len;
            }

            co -= len;
            list.push(...append);
            this.prizeList = list;
            await this.$nextTick();
            const all = dom.querySelectorAll('.gmodel');
            const dom2 = all[co + index];
            this.endMove = dom2.offsetLeft - all[0].offsetLeft;
            this.$parent.getTabNum();

            setTimeout(() => {
                this.ani = 'ani';
                setTimeout(() => {
                    this.inGame = false;
                    this.showPrize = true;
                }, 5000)
            }, 1000)
            this.getDrawNum();
        },
        getDrawNum() {
            return this.$axios.post(this.baseURLApp + "/anniversary/taskGift/queryNumOfDrawsAndRewards", {
                hasWx: true,
                userId: this.userId,
            }).then((res) => {
                this.numOfLottery = res.data.drawNum;
                this.rewardNum = res.data.rewardNum;
            })
        },
        openDrawFrom() {
            this.showDrawFrom = true;
            this.getLotteryDetail(true);
        },
        getLotteryDetail(refresh) {
            const size = 10;
            if (refresh) {
                this.pageNum = 1;
                this.drawFromList = [];
                this.loading = true;
            } else {
                this.pageNum += 1;
            }

            this.$axios.post(this.baseURLApp + "/anniversary/taskGift/pageLotteryDetail", {
                hasWx: true,
                userId: this.userId,
                current: this.pageNum,
                size
            }).then((res) => {
                const d = res.data && res.data.drawInfos || [];
                if (!d.length && this.drawFromList.length == 0) {
                    this.hasData = false;
                } else {
                    this.hasData = true;
                }
                if (refresh) {
                    this.drawFromList = d;
                } else {
                    this.drawFromList.push(...d);
                }
                this.finished = d.length < size;
                this.loading = false;
                this.numOfLottery = res.data.drawNum;
            })
        },
        getLen() {
            const arr = getEmojiArray(this.content);
            return arr.length
        },
        addData() {
            if (!this.content) {
                this.$toast('请输入FLAG内容');
                return;
            }
            if (this.getLen() > 20) {
                this.$toast('请输入20字以内~');
                return;
            }
            if (checkIsXSS(this.content)) {
                this.$toast('输入内容异常');
                return;
            }
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType: 'spinner',
            })
            this.$axios.post(this.baseURLApp + "/anniversary/taskGift/setUpFlag", {
                hasWx: true,
                userId: this.userId,
                flag: this.content
            }).then((res) => {
                this.showFlag = false;
                if (res.data.status == -1) {
                    this.$toast('今日已立了足够多的FLAG，明天再来吧');
                    return;
                }
                this.$refs.refBarrage.fastData({
                    text: this.content,
                    class: "color-my"
                })
                this.$toast.clear();
                if (res.data.status === 0) {
                    this.$toast('完成每日首次FLAG，抽奖机会+1');
                } else {
                    this.$toast('已立下FLAG~');
                }
                this.getData();
                this.getDrawNum();
                this.$parent.getTabNum();
            })
        },
    }
}
</script>
<style lang="less">
.com_mission {
    * {
        box-sizing: border-box;
    }

    img {
        vertical-align: top;
        width: 100%;
    }

    .title {
        width: 304px;
        height: 69px;
        margin: 0 auto 20px;
        overflow: hidden;
    }

    .barrage {
        position: relative;
        height: 400px;
    }

    .color-my {
        &::before {
            content: '我';
            // position: absolute;
            width: 32px;
            height: 32px;
            color: #fff;
            border-radius: 4px;
            font-size: 22px;
            text-align: center;
            line-height: 32px;
            background: #17A8FC;
            display: inline-table;
            position: relative;
            top: -2px;
        }
    }

    .send-sub {
        width: 616px;
        height: 122px;
        display: block;
        margin: 0 auto 60px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: 94px;
            height: 36px;
            background: url(https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/88buy-preheat-2024/mission/num.png);
            background-size: 100% 100%;
            right: 10px;
            transform: translateY(-50%);
            top: 10px;
        }
    }

    .bg1 {
        width: 696px;
        height: 731px;
        margin: 0 auto;
        background: url(https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/88buy-preheat-2024/mission/bg1.png);
        background-size: 100% 100%;
        padding-top: 102px;

        .gbox {
            width: 657px;
            height: 331px;
            background: url(https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/88buy-preheat-2024/mission/gbox.png);
            background-size: 100% 100%;
            margin: 0 auto;
            padding: 0 12px;
            display: flex;
            align-items: center;
            position: relative;

            @keyframes move {
                0% {
                    transform: translate3d(0, 0, 0);
                    // left: 0
                }

                100% {
                    transform: translate3d(var(--endMove), 0, 0);
                    // left: var(--endMove)
                }
            }

            .ani {
                animation: move 4s ease-out forwards;
            }

            &.in-xscroll {
                .pd {
                    pointer-events: none;
                }

                .get {
                    opacity: 1;
                }
            }

            .pd {
                margin-top: 10px;
                // padding: 0 6px;
                white-space: nowrap;
                overflow-x: scroll;
                position: relative;

                .trans-box {}
            }

            .gmodel {
                margin-right: 6px;
                width: 142px;
                display: inline-table;

                &:first-of-type {
                    margin-left: 12px;
                }

                &:last-of-type {
                    margin-right: 12px;
                }

                .g1 {
                    height: 161px;
                    background: url(https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/88buy-preheat-2024/mission/gbg1.png);
                    background-size: 100% 100%;
                    padding-top: 30px;
                    text-align: center;

                    img {
                        width: 120px;
                        height: 120px;
                    }
                }

                .g2 {
                    height: 74px;
                    background: url(https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/88buy-preheat-2024/mission/gbg2.png);
                    background-size: 100% 100%;
                    position: relative;
                    margin-top: -20px;
                    padding: 0 18px;
                    display: flex;
                    align-items: center;

                    .pname {
                        font-size: 18px;
                        color: #0A6189;
                        font-weight: bold;
                        white-space: normal;
                        text-align: center;
                        width: 100%;
                    }
                }
            }

            .get {
                position: absolute;
                left: 76px;
                bottom: 17px;
                width: 37px;
                height: 28px;
                background: url(https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/88buy-preheat-2024/mission/get.png);
                background-size: 100% 100%;
                opacity: 0;
                transition: opacity 0.5s
            }
        }

        .sub-draw {
            width: 635px;
            height: 115px;
            background: url(https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/88buy-preheat-2024/mission/sub2.png);
            background-size: 100% 100%;
            margin: 21px auto 8px;
        }

        .info-line {
            width: 630px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            .il-model {
                width: 304px;
                height: 97px;
                line-height: 94px;
                background: url(https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/88buy-preheat-2024/mission/sub3.png);
                background-size: 100% 100%;
                font-size: 24px;
                color: #176C6B;
                padding-left: 33px;

                .nf {
                    font-size: 42px;
                    color: #125D53;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }

    .bg2 {
        position: relative;
        z-index: 1;
        margin: -30px auto;
        width: 733px;
        height: 64px;
        background: url(https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/88buy-preheat-2024/mission/bg2.png);
        background-size: 100% 100%;
    }

    .bg3 {
        padding-top: 139px;
        margin: 0 auto;
        width: 709px;
        height: 728px;
        background: url(https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/88buy-preheat-2024/mission/bg3.png);
        background-size: 100% 100%;

        .mission-list {
            width: 650px;
            margin: 15px auto 65px;


            .mission-model {
                height: 124px;
                background: #EDFFFD;
                border-radius: 20px;
                margin-bottom: 16px;
                display: flex;

                align-items: center;
                padding: 0 24px 0 28px;

                &:last-of-type {
                    margin-bottom: 32px;
                }

                .mission-logo {
                    width: 76px;
                    height: 76px;
                    border-radius: 50%;
                    overflow: hidden;
                    background: #f5f5f5;

                    img {
                        height: 100%;
                    }
                }

                .mission-info {
                    flex: 1;
                    margin-left: 23px;
                    color: #242831;

                    .mission-name {
                        font-weight: bold;
                        font-size: 28px;
                        line-height: 1;
                    }

                    .mission-num {
                        margin-top: 15px;
                        font-size: 22px;
                        line-height: 1;
                    }
                }

                .mission-sub {
                    width: 120px;
                    height: 52px;
                    background: linear-gradient(90deg, #FF6C4F 0%, #FF4C7F 100%);
                    border-radius: 10px;
                    font-size: 22px;
                    color: #FFFFFF;
                    font-weight: bold;
                    text-align: center;
                    line-height: 52px;

                    &.dis {
                        opacity: 0.6;
                        pointer-events: none;
                    }
                }
            }
        }
    }

    .popprize {
        width: 662px;
        height: 890px;
        background: url(https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/88buy-preheat-2024/mission/bg.png);
        background-size: 100% 100%;
        padding-top: 291px;
        color: #fff;
        text-align: center;
        overflow: visible;

        .jackpot-name {
            font-size: 44px;
            font-weight: bold;
            line-height: 1;
            margin: 0 auto 24px;
            width: 475px;
            // font-family: PuHuiTi;
            color: #1E242B;
        }

        .jackpot-pic {
            width: 260px;
            height: 260px;
            margin: 0 auto;

            img {
                width: 100%;
                height: 100%;
            }

        }

        .jackpot-label {
            font-size: 22px;
            line-height: 42px;
            color: #DF4400;
            text-align: center;
            margin-top: 39px;
        }

        .jackpot-subline {
            position: absolute;
            display: flex;
            justify-content: space-between;
            width: 480px;
            left: 50%;
            transform: translateX(-50%);
            bottom: 50px;

            .jackpot-sub-model {
                width: 230px;
                height: 96px;
                line-height: 98px;
                border-radius: 48px;
                text-align: center;
                font-size: 36px;
                font-weight: bold;
                color: #fff;
            }

            .jackpot-sub-1 {
                background: #7AB6FF;
            }

            .jackpot-sub-2 {
                background: #3A8DF3;
            }
        }

        .popprize-close {
            position: absolute;
            top: 100%;
            margin-top: 60px;
            text-align: center;
            width: 100%;

            img {
                width: 60px;
                height: 60px;
            }
        }
    }

    .nulldata {
        height: 100%;
        padding-top: 181px;

        img {
            width: 320px;
            height: 320px;
            display: block;
            margin: 0 auto;
        }

        .nulltip {
            font-size: 28px;
            color: #6C727A;
            line-height: 1;
            margin-top: 21px;
            text-align: center;
        }
    }
    .pop-title {
        color: #1E242B;
        font-weight: bold;
        background: linear-gradient(rgba(104, 201, 233, 0.3) 0%, #FFFFFF 100%);
        font-size: 36px;
        text-align: center;
        height: 116px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .ts {
            position: relative;
            transform-style: preserve-3d;
            display: inline-block;

            &::after {
                content: '';
                position: absolute;
                left: -8px;
                bottom: 0px;
                width: 160px;
                height: 18px;
                background: linear-gradient(90deg, #6BADFF 0%, rgba(255, 234, 59, 0) 100%);
                border-radius: 38px;
                transform: translateZ(-10px);
                z-index: -1;
            }
        }

        .pop-close {
            position: absolute;
            right: 32px;
            top: 38px;
            width: 44px;
            height: 44px;
            line-height: 44px;
            border-radius: 50%;
            background: rgba(39, 40, 49, 0.2);
            font-size: 28px;
            text-align: center;
        }
    }

    .popnonum {
        background: #F4FAFF;
        width: 610px;
        height: 366px;
        border-radius: 40px;
        padding-top: 60px;
        text-align: center;

        .line1 {
            font-size: 36px;
            color: #1A1A1A;
            line-height: 1;
            font-family: PuHuiTi;
        }

        .line2 {
            margin-top: 43px;
            line-height: 1;
            font-size: 24px;
            color: #1A1A1A;
            line-height: 42px;
        }

        .num-subline {
            position: absolute;
            left: 36px;
            right: 36px;
            bottom: 44px;
            background: linear-gradient(90deg, #49BDFF 0%, #12ABF1 100%);
            height: 90px;
            line-height: 90px;
            color: #fff;
            text-align: center;
            font-size: 32px;
            font-weight: bold;
            border-radius: 45px;
        }
    }

    .popsend {
        width: 610px;
        border-radius: 40px;
        background: #fff;
        padding: 35px;
        overflow: visible;

        .top-jb {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            height: 120px;
            background: linear-gradient(0deg, #FFFFFF 0%, rgba(104,201,233,0.3) 100%);
            border-radius: 40px 40px 0px 0px;
        }

        .send-head {
            font-size: 40px;
            // font-weight: bold;
            color: #16304F;
            position: relative;
            padding-left: 10px;
            height: 75px;
            font-family: PuHuiTi;
            &::after {
                content: '';
                position: absolute;
                width: 152px;
                height: 176px;
                background: url(https://img.chaolu.com.cn/ACT/anniversary-2024/index/logo1.png);
                background-size: 100% 100%;
                right: 38px;
                bottom: 0;
            }
        }

        .textarea {
            height: 200px;
            background: url(https://img.chaolu.com.cn/ACT/88buy-preheat-2024/mission/textareabg.png);
            background-size: 100% 100%;
            margin: 0 0 30px;
            padding: 25px 25px 20px;
            position: relative;

            textarea {
                width: 100%;
                height: 120px;
                background: transparent;
                border: none;
                line-height: 42px;
                font-size: 24px;
            }

            .content-len {
                color: #6C727A;
                font-size: 24px;
                line-height: 1;
                position: absolute;
                bottom: 19px;
                right: 25px;
                text-align: right;
            }
        }

        .say-label {
            color: #402828;
            font-size: 24px;
            font-weight: bold;
            line-height: 1;
            padding-left: 9px;
        }

        .say-box {
            max-height: 260px;
            overflow: auto;

            .choose-model {
                display: inline-flex;
                padding: 0 30px;
                background: #E5F4FF;
                border-radius: 25px;
                height: 49px;
                font-size: 22px;
                color: #000000;
                line-height: 1;
                align-items: center;
                margin-right: 20px;
                margin-top: 20px;
            }
        }

        .send-subline {
            margin-top: 36px;
            height: 80px;
            background: linear-gradient(90deg, #66C7FF 0%, #12ABF1 100%);
            border-radius: 40px;
            font-size: 32px;
            color: #FFFFFF;
            font-weight: bold;
            text-align: center;
            line-height: 82px;
        }

        .popsend-close {
            position: absolute;
            top: 100%;
            left: 0;
            margin-top: 70px;
            text-align: center;
            width: 100%;

            img {
                width: 60px;
                height: 60px;
            }
        }
    }

    .popdrawfrom {
        border-radius: 24px 24px 0px 0px;
        background: #fff;

        .draw-total {
            font-size: 24px;
            color: #242831;
            text-align: center;
        }

        .drawfrom-box {
            height: 800px;
            overflow: auto
        }

        .drawfrom-model {
            padding: 42px 9px;
            margin: 0 32px;
            border-bottom: 1px solid #ECECEC;
            color: #242831;

            .drawfrom-way {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-weight: bold;
                line-height: 1;


                .drawfrom-way-1 {
                    font-size: 28px;
                }

                .drawfrom-way-2 {
                    font-size: 28px;

                    .color-red {
                        color: #F03C18;
                    }
                }
            }

            .drawfrom-time {
                font-size: 22px;
                margin-top: 26px;
                line-height: 1;
            }
        }
    }
}</style>