export function getEmojiArray(val) { // 字符串转成数组
    let rsAstralRange = '\\ud800-\\udfff',
        rsZWJ = '\\u200d',
        rsVarRange = '\\ufe0e\\ufe0f',
        rsComboMarksRange = '\\u0300-\\u036f',
        reComboHalfMarksRange = '\\ufe20-\\ufe2f',
        rsComboSymbolsRange = '\\u20d0-\\u20ff',
        rsComboRange = rsComboMarksRange + reComboHalfMarksRange + rsComboSymbolsRange;
    let reHasUnicode = RegExp('[' + rsZWJ + rsAstralRange + rsComboRange + rsVarRange + ']');

    let rsFitz = '\\ud83c[\\udffb-\\udfff]',
        rsOptVar = '[' + rsVarRange + ']?',
        rsCombo = '[' + rsComboRange + ']',
        rsModifier = '(?:' + rsCombo + '|' + rsFitz + ')',
        reOptMod = rsModifier + '?',
        rsAstral = '[' + rsAstralRange + ']',
        rsNonAstral = '[^' + rsAstralRange + ']',
        rsRegional = '(?:\\ud83c[\\udde6-\\uddff]){2}',
        rsSurrPair = '[\\ud800-\\udbff][\\udc00-\\udfff]',
        rsOptJoin = '(?:' + rsZWJ + '(?:' + [rsNonAstral, rsRegional, rsSurrPair].join('|') + ')' + rsOptVar + reOptMod + ')*',
        rsSeq = rsOptVar + reOptMod + rsOptJoin,
        rsSymbol = '(?:' + [rsNonAstral + rsCombo + '?', rsCombo, rsRegional, rsSurrPair, rsAstral].join('|') + ')';
    let reUnicode = RegExp(rsFitz + '(?=' + rsFitz + ')|' + rsSymbol + rsSeq, 'g');
    function hasUnicode(val) {
        return reHasUnicode.test(val);
    }
    function unicodeToArray(val) {
        return val.match(reUnicode) || [];
    }
    function asciiToArray(val) {
        return val.split('');
    }
    return hasUnicode(val)
        ? unicodeToArray(val)
        : asciiToArray(val);
}

// 表情转码
export function Zemoji(str) {
    const patt = /[\ud800-\udbff][\udc00-\udfff]/g; // 检测utf16字符正则
    str = str.replace(patt, (char) => {
        let H;
        let L;
        let code;
        let s;

        if (char.length === 2) {
            H = char.charCodeAt(0); // 取出高位
            L = char.charCodeAt(1); // 取出低位
            code = (H - 0xD800) * 0x400 + 0x10000 + L - 0xDC00; // 转换算法
            s = `&#${code};`;
        } else {
            s = char;
        }

        return s;
    });

    return str;
}
// 表情解码
export function Jemoji(strObj) {
    const patt = /&#\d+;/g;
    const arr = strObj.match(patt) || [];

    let H;
    let L;
    let code;

    for (let i = 0; i < arr.length; i += 1) {
        code = arr[i];
        code = code.replace('&#', '').replace(';', '');
        // 高位
        H = Math.floor((code - 0x10000) / 0x400) + 0xD800;
        // 低位
        L = ((code - 0x10000) % 0x400) + 0xDC00;
        code = `&#${code};`;
        const s = String.fromCharCode(H, L);
        strObj = strObj.replace(code, s);
    }
    return strObj;
}

export function isEmojiCharacter(substring) {
    for (var i = 0; i < substring.length; i++) {
        var hs = substring.charCodeAt(i);
        if (0xd800 <= hs && hs <= 0xdbff) {
            if (substring.length > 1) {
                var ls = substring.charCodeAt(i + 1);
                var uc = ((hs - 0xd800) * 0x400) + (ls - 0xdc00) + 0x10000;
                if (0x1d000 <= uc && uc <= 0x1f77f) {
                    return true;
                }
            }
        } else if (substring.length > 1) {
            var ls = substring.charCodeAt(i + 1);
            if (ls == 0x20e3) {
                return true;
            }
        } else {
            if (0x2100 <= hs && hs <= 0x27ff) {
                return true;
            } else if (0x2B05 <= hs && hs <= 0x2b07) {
                return true;
            } else if (0x2934 <= hs && hs <= 0x2935) {
                return true;
            } else if (0x3297 <= hs && hs <= 0x3299) {
                return true;
            } else if (hs == 0xa9 || hs == 0xae || hs == 0x303d || hs == 0x3030
                || hs == 0x2b55 || hs == 0x2b1c || hs == 0x2b1b
                || hs == 0x2b50) {
                return true;
            }
        }
    }
}
// let strarr = getEmojiArray('12👩‍👩‍👧‍👦🌺');console.log(strarr)
// let str = '', strlen = 0, num = 0, lastarr = [];
// for (let i = 0; i < strarr.length; i++) {
//     strlen += strarr[i].length; // 数组中每一个字符的长度相加
//     if (strlen > this.limit) { // 如果长度大于限制
//         num = i; // 记录下到第几个开始超出限制
//         lastarr = strarr.slice(0, this.limit); // 取出限制内的数组
//         this.$toast('长度超出了');
//         break; // 退出循环
//         // return;
//     }
// }
// if (strlen > this.limit) { // 如果输入的第一个数长度大于限制的表情
//     for (var j = 0; j < num; j++) {
//         str += lastarr[j]; // 取出限制内的数组的字符
//     }
//     this.currentValue = str;
// }