<template>
    <van-popup v-model="showMyPrize" position="bottom" safe-area-inset-bottom class="popmyprize">
        <div class="pop-title">
            <span class="ts">我的奖品</span>
            <van-icon name="cross" class="pop-close" color="#fff" @click="showMyPrize = false" />
        </div>
        <div class="myprize-box">
            <template v-if="hasData">
                <van-list v-model="loading" :finished="finished" @load="loadList" :immediate-check="false" :offset="50"
                    finished-text="没有更多了">
                    <div class="myprize-model" v-for="item in myPrizeList">
                        <div class="myprize-pic">
                            <img :src="item.picUrl" />
                        </div>
                        <div class="myprize-info">
                            <div class="myprize-info-top">
                                <div class="myprize-info-name">{{ item.prizeName }}</div>
                                <template v-if="item.sendType=='COLLECT_IN_STORE'">
                                    <div class="myprize-get" @click="get(item)" v-if="item.claimStatus=='NOT_RECEIVE'" >去领取</div>
                                    <div class="myprize-get op" v-else >已领取</div>
                                </template>
                                <template v-if="item.sendType=='CHOOSE_CITY'">
                                    <div class="myprize-get" @click="dh(item)" v-if="item.claimStatus=='NOT_RECEIVE'" >去兑换</div>
                                    <div class="myprize-get op"  v-else >已兑换</div>
                                </template>
                            </div>
                            <div class="myprize-info-from">{{ item.obtainDate }} 获得</div>
                            <div class="myprize-info-use" >{{ item.copywriting }}</div>
                        </div>
                    </div>
                </van-list>
            </template>
            <div class="nulldata" v-else>
                <img src="https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/anniversary-2024/index/null.png" />
                <div class="nulltip">暂无中奖记录</div>
            </div>
        </div>
        <SelectCity ref="refSelectCity" @setCity="setCity" />
    </van-popup>
</template>
<script>
import userMixin from '@/mixin/userMixin';
import SelectCity from './selectCity.vue';
export default {
    mixins: [userMixin],
    components: {
        SelectCity
    },
    data() {
        return {
            showMyPrize: false,
            myPrizeList: [],
            pageNum: 1,
            finished: false,
            loading: false,
            hasData: true,
            showCity:false,
        }
    },
    methods: {
        async setCity(cityId) {
            const res = await this.$axios.post(this.baseURLApp + "/anniversary/taskGift/awarding", {
                hasWx: true,
                userId: this.userId,
                cityId,
                participateId:this.prizeItem.participateId
            })
            this.getMyPrize(true);
            this.$toast('兑换成功');
        },
        dh(item){
            this.prizeItem=item;
            this.$refs.refSelectCity.isCityShow=true;
        },
        get(item){
            this.$router.push({path:'/superdeer-activity/88buy-preheat-2024/receive',query:{
                participateId:item.participateId
            }})
        },

        loadList() {
            console.log('load')
            this.pageNum += 1;
            this.getMyPrize();
        },
        getMyPrize(refresh) {
            const size = 10;
            this.$axios.post(this.baseURLApp + "/anniversary/taskGift/pageUserReward", {
                hasWx: true,
                userId: this.userId,
                current: this.pageNum,
                size
            }).then((res) => {
                const d = res.data.prizeInfos || [];
                if (!d.length && this.myPrizeList.length == 0) {
                    this.hasData = false;
                } else {
                    this.hasData = true;
                }
                if (refresh) {
                    this.myPrizeList = d;
                } else {
                    this.myPrizeList.push(...d);
                }
                this.finished = d.length < size;
                this.loading = false;
            })
        },
        show() {
            this.loading = true;
            this.pageNum = 1;
            this.myPrizeList = [];
            this.getMyPrize(true);
            this.showMyPrize = true;
        }
    }
}
</script>
<style scoped lang="less">
.popmyprize {
    border-radius: 24px 24px 0px 0px;
    background: #F5F5F5;

    div {
        box-sizing: border-box;
    }

    img {
        vertical-align: top;
        width: 100%;
    }

    .pop-title {
        background: linear-gradient(rgba(104, 201, 233, 0.3) 0%, #F5F5F5 100%);
    }

    .myprize-box {
        height: 800px;
        overflow: auto
    }

    .myprize-model {
        // height: 192px;
        background: #FFFFFF;
        border-radius: 16px;
        display: flex;
        padding: 24px;
        margin: 20px 32px 0;

        .myprize-pic {
            width: 144px;
            height: 144px;
            margin-right: 36px;

            img {
                height: 100%
            }
        }

        .myprize-info {
            flex: 1;
            padding-top: 5px;

            .myprize-info-top {
                display: flex;
                justify-content: space-between;
            }

            .myprize-info-name {
                font-size: 32px;
                color: #242831;
                font-weight: bold;
            }

            .myprize-get {
                width: 120px;
                height: 52px;
                background: #3A8DF3;
                border-radius: 10px;
                font-size: 22px;
                color: #FFFFFF;
                text-align: center;
                line-height: 54px;
            }
            .op{
                opacity: 0.6;
            }
            .myprize-info-from {
                font-size: 22px;
                color: #242831;
                line-height: 1;
                margin: 24px 0 12px;
            }

            .myprize-info-use {
                font-size: 20px;
                color: #242831;
                line-height: 26px;
            }
        }
    }
}
</style>