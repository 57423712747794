<template>
  <div class="content">
    <!-- 沉浸式头部 -->
    <nav-bar header-title="超鹿全民健身季" backColor="#fff" :show-back="true" :header-back="newAppBack"
      @setBarHeight="setBarHeight" />
    <div class="top-box" ref="topRef">
      <img class="top" :src="baseImgUrl + 'top-bg3.png'" alt="">
      <div class="rs">
        <div class="sec1" @click="showShareFun">分享</div>
        <div class="sec2" @click="ruleShow = true">规则</div>
      </div>
      <img class="arrow" :src="baseImgUrl + 'arrow.png'" alt="">
    </div>
    <div class="tab-box" :style="{ top: barHeight + 'px' }" ref="tabRef">
      <div @click="tabChange(1)" :class="tabIndex == 1 ? 'act' : ''">
        <span class="tips" v-if="tabNum.drawNum > 0">可抽奖{{ tabNum.drawNum }}次</span>
        <span class="line1">立运动Flag</span><span class="line2">抽超值福利券</span>
      </div>
      <div @click="tabChange(2)" :class="tabIndex == 2 ? 'act' : ''">
        <span class="tips" v-if="tabNum.unCheckInNum > 0">本周差{{ tabNum.unCheckInNum }}节</span>
        <span class="line1">团课玩家</span><span class="line2">打卡得限定徽章</span>
      </div>
      <div @click="tabChange(3)" :class="tabIndex == 3 ? 'act' : ''">
        <span class="tips" v-if="tabNum.notEnterNum > 0">{{ tabNum.notEnterNum }}名好友未进馆</span>
        <span class="line1">邀新有礼</span><span class="line2">{{ activityEnd ? '3天卡' : '赢徽章+3天卡' }}</span>
      </div>
    </div>
    <div class="content-box">
      <div v-if="tabIndex == 1">
        <Mission ref="missRef" />
        <div class="e-badge-box">
          <div class="e-badges">
            <div v-for="(i, index) in eBadgeList" @click="showEbadge(i)">
              <img :src="i.imgUrl" alt="">
              <p class="mt20 f24 fw6">{{ i.name }}</p>
              <p class="l2" v-if="index == 0">完成3节<br />私教课</p>
              <p class="l2" v-else-if="index == 1">完成1节<br />小班课</p>
              <p class="l2" v-else-if="index == 2">累计自助时长<br />8小时</p>
              <p class="l2" v-else>分享<br />活动海报</p>
              <div class="l3" v-if="!activityEnd">{{ i.curProgress }}/{{ i.target }}</div>
              <div class="l3" v-else>已结束</div>
            </div>
          </div>
          <p class="e-tips">*以上课程需签到记录为有效计入 <br/>完成上方的运动任务，自动点亮徽章并发放账户中</p>
          <p class="f22 tc">限量{{ limitedReward.rewardTotalNum }}份，当前仅剩{{ limitedReward.rewardNum }}份</p>
          <img class="big-badge" :src="limitedReward.rewardImg" alt="">
          <div class="eb-btns">
            <img v-if="limitedReward.rewardStatus == 'NOT_RECEIVE'" @click="getEbigBadge"
              :src="baseImgUrl + 'get-btn1.png'" alt="">
            <img v-else-if="limitedReward.rewardStatus == 'NOT_FINISH'" :src="baseImgUrl + 'get-btn2.png'" alt="">
            <img v-else-if="limitedReward.rewardStatus == 'RECEIVED'" @click="eBadgeDetailShow"
              :src="baseImgUrl + 'get-btn3.png'" alt="">
            <img v-else-if="limitedReward.rewardStatus == 'NO_STOCK'" :src="baseImgUrl + 'get-btn4.png'" alt="">
          </div>
        </div>
      </div>
      <div v-if="tabIndex == 2">
        <div class="tc"><img class="team-title" :src="baseImgUrl + 'team-title.png'" alt=""></div>
        <div class="team-box">
          <p class="detail" @click="getTeamDetail">团课打卡明细<van-icon name="arrow" /></p>
          <template v-if="!activityEnd">
            <p class="l1">本周已打卡 {{ thisWeekNum }}/4 节</p>
            <p class="l2">*仅限非月卡免费团课</p>
            <div class="progress">
              <div class="p">
                <span class="p-bg"></span>
                <span class="p-color"
                  :style="{ width: (thisWeekNum >= 4 ? 4 : thisWeekNum) / 4 * 0.95 * 100 + '%' }"></span>
                <div class="points">
                  <div v-for="i in 5">
                    <img class="point"
                      :src="baseImgUrl + 'point' + (i == 1 ? '0' : (i <= (thisWeekNum + 1) ? '2' : '1')) + '.png'" alt="">
                    <span>{{ i < 2 ? '进度' : ((i - 1) + '节') }}</span>
                  </div>
                </div>
              </div>
            </div>
            <p class="l3">
              <span v-if="thisWeekNum < 4" class="row-start-center">本周还差{{ 4 - thisWeekNum }}节就可解锁<span>定制徽章</span></span>
              <span v-else class="row-start-center">本周打卡完成！成功解锁<span>定制徽章</span></span>
            </p>
            <div class="p-btn" @click="jumpWhere('team')"></div>
          </template>
          <div class="empty-box" v-else>
            <img :src="baseImgUrl + 'empty.png'" alt="">
            <p>活动已结束</p>
          </div>
        </div>
        <div class="badge-box">
          <div class="f24 tr">
            <span class="can">可领{{ receiveTime }}枚</span>
            <span class="fw6" @click="getBadgeListFun">查看明细<van-icon name="arrow" class="ml4" /></span>
          </div>
          <p class="f22 tc mt60 mb30" style="line-height: 20px;">*每周打卡四节解锁一枚徽章，徽章与奖牌均为实体奖品，<br />活动结束后可至门店领取。</p>
          <div class="bs">
            <div v-for="i in rewardInfo">
              <img :src="i.rewardImg" :class="i.rewardStatus == 'RECEIVED' ? '' : 'gray'" alt="">
              <p>{{ i.rewardName }}</p>
              <p class="b-btn" :class="i.rewardStatus == 'NOT_RECEIVE' ? '' : 'o6'" @click="getSmallBadge(i)">{{
                btnText[i.rewardStatus] }}</p>
            </div>
          </div>
          <p class="l2">
            <span v-if="getNum > 0">还差{{ getNum }}枚徽章即可兑换 <span>超鹿全民健身季限定奖牌</span></span>
            <span v-else>已集齐4枚徽章！可兑换<span>超鹿全民健身季限定奖牌</span></span>
          </p>
          <div class='big-badge'>
            <div class="box">
              <img class="badge-img" :src="limitedRewardInfo.rewardImg" alt="">
              <div class="pub_onefull">
                <p class="f28 fw6">{{ limitedRewardInfo.rewardName }}</p>
                <p class="b-l2"><span>实体</span><span>限量{{ limitedRewardInfo.rewardTotalNum }}份</span></p>
                <p class="f20">当前仅剩{{ limitedRewardInfo.rewardNum }}份</p>
              </div>
              <div @click="getBigBadge" class="big-btn"
                :class="limitedRewardInfo.rewardStatus == 'NOT_RECEIVE' ? 'get' : ''">
                <span>{{ btnText[limitedRewardInfo.rewardStatus] }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="tabIndex == 3">
        <invite-gift activity="2024-88"></invite-gift>
      </div>
    </div>
    <!-- 分享 -->
    <common-share :value="showShare" :shareParams="shareParams" @close="showShare = false"
      @share-success="shareSuccessFun" :miniSharePoster="appTypeStr === 'mini'"
      :shareItem="['minifriend', 'postermessage', 'save']" :reload="true">
      <template slot="createDom">
        <div class="canvascss">
          <img :src="baseImgUrl + 'sharebg.png'" class="canvascss_bg" />
          <div class="canvascss_left">
            <img :src="userDetail.headImg" />
          </div>
          <div class="canvascss_name ellipsis">
            {{ userDetail.nickName }}
          </div>
          <div class="canvascss_right">
            <img v-if="userDetail.qrCode" :src="'data:image/png;base64,' + userDetail.qrCode" />
          </div>
        </div>
      </template>
    </common-share>
    <!-- 规则 -->
    <van-popup v-model="ruleShow" round position="bottom" safe-area-inset-bottom class="pop rule-pop" closeable
      :close-icon="baseImgUrl + 'close.png'">
      <div class="title"><span class="bg"><span>活动规则</span></span></div>
      <div class="rule-box">
        <p class="fw6">一、立运动Flag 抽超值福利券</p>
        <p>1.活动时间：2024年7月15日至2024年8月18日</p>
        <p>2.活动规则：</p>
        <p>①
          活动期间用户参与活动发送每日flag即可参与抽奖，礼包内包含优惠券、课包、实物奖品等礼品，活动期间赠送的优惠券不可与其他优惠券、课包券、礼品卡叠加使用；若您使用活动优惠券预约的团课申请退款，则优惠券不予退还；优惠券限用一次，不提现，不找零，不可转赠；获得的礼品则需前往就近门店领取，奖品图片仅供参考，具体以门店领取的实物为准；
        </p>
        <p>② 活动期间用户完成指定任务即可点亮徽章；点亮4个线上徽章，即可兑换超鹿全民健身季实体奖牌，限量888份，先到先得，送完为止；
          每人限领1份，实物奖品徽章需在2024年9月15日-2024年10月15日期间，自选超鹿运动线下门店领取，逾期则以放弃领取资格处理，请务必知悉。奖品图片仅供参考，具体以门店领取的实物为准。</p>
        <p>③ 活动期间赠送的优惠券不可与其他优惠券、课包券、礼品卡叠加使用；若您使用活动优惠券预约的团课申请退款，则优惠券不予退还；优惠券限用一次，不提现，不找零，不可转赠。</p>
        <p>④ 如果您对活动有疑问，请在超鹿 APP 上联系在线客服或者拨打400-700-5678进行反馈(在线时间：9：30-12：00/13：30-18：30)</p>
        <p class="fw6">二、团课玩家 打卡得限定徽章</p>
        <p>1.活动时间：2024年7月15日至2024年8月18日</p>
        <p>2.活动规则：</p>
        <p>① 活动期间用户每周完成4节团课，即可自行选择获得任意一枚超鹿定制徽章；</p>
        <p>②
          活动期间用户集齐4枚徽章即可兑换超鹿全民健身季实体限定奖牌，限量888份，数量有限，送完为止；每人限领1份，实物奖品徽章需在2024年9月15日-2024年10月15日期间，自选超鹿运动线下门店领取，逾期则以放弃领取资格处理，请务必知悉。奖品图片仅供参考，具体以门店领取的实物为准。
        </p>
        <p>③ 如果您对活动有疑问，请在超鹿 APP 上联系在线客服或者拨打400-700-5678进行反馈(在线时间：9：30-12：00/13：30-18：30)</p>
      </div>
    </van-popup>
    <!-- 团课打卡明细 -->
    <van-popup v-model="teamDetailShow" round position="bottom" safe-area-inset-bottom class="pop team-detail" closeable
      :close-icon="baseImgUrl + 'close.png'">
      <div class="title"><span class="bg"><span>团课打卡明细</span></span></div>
      <template v-if="teamDetailList.length > 0">
        <p class="tips">仅统计非月卡免费团课数据</p>
        <div class="pop-c">
          <div v-for="i in teamDetailList">
            <p class="f28 fw6">{{ i.courseName }}-{{ i.teacherName }}教练 </p>
            <p class="f24 mt20">{{ i.endTime }}</p>
          </div>
        </div>
      </template>
      <div class="empty-box" v-else>
        <img :src="baseImgUrl + 'empty.png'" alt="">
        <p>暂无团课打卡明细</p>
      </div>
    </van-popup>
    <!-- 徽章领取二次确认弹窗 -->
    <van-popup v-model="badgeGetShow" class="get-pop" :overlay-style="{ background: 'rgba(0, 0, 0, 0.85)' }">
      <div>
        <img class="b-img" :src="getItem.rewardDesc" alt="">
        <p class="g-name">{{ getItem.rewardName }}</p>
        <p class="f24">*领取后徽章将以实物券形式发放至账户中</p>
        <img class="btn" :src="baseImgUrl + 'get-btn.png'" alt="" @click="confirmBadge">
        <br />
        <img class="g-close" :src="baseImgUrl + 'close2.png'" alt="" @click="badgeGetShow = false">
      </div>
    </van-popup>
    <!-- 已领取徽章详情 -->
    <van-popup v-model="getBadgeDetailShow" round position="bottom" safe-area-inset-bottom class="pop badge-detail"
      closeable :close-icon="baseImgUrl + 'close.png'">
      <div class="title"><span class="bg"><span>领取记录</span></span></div>
      <p class="mt40">奖品已发放至您的账户</p>
      <template v-if="tabIndex == 1">
        <p class="mt5">请至个人中心-我的勋章内查看</p>
      </template>
      <template v-if="tabIndex == 2">
        <p class="mt5" v-if="checkBadgeItme.isBig">请到选择的门店找教练兑换领取</p>
        <p class="mt5" v-else>请到超鹿任一门店找教练兑换领取</p>
      </template>
      <div class="gray-line"></div>
      <div class="b-detail">
        <img :src="checkBadgeItme.rewardImg" alt="">
        <div>
          <p class="fw6">{{ checkBadgeItme.rewardName }}</p>
          <p class="f24 mt35" v-if="checkBadgeItme.isBig">兑换门店：{{ checkBadgeItme.receiveVenueName }}</p>
          <p class="f24 mt35" v-else>已发放至账户中</p>
        </div>
      </div>
    </van-popup>
    <!-- 领取机会明细 -->
    <van-popup v-model="getBadgeListShow" round position="bottom" safe-area-inset-bottom class="pop get-badge-list"
      closeable :close-icon="baseImgUrl + 'close.png'">
      <div class="title"><span class="bg"><span>领取机会明细</span></span></div>
      <template v-if="badgeList.length > 0">
        <p class="tips">剩余领取机会：{{ receiveTime }}</p>
        <div class="pop-c">
          <div v-for="i in badgeList">
            <p class="f28 row-between-center">
              <span class="fw6">{{ i.describe }}</span><span :style="{ color: i.num > 0 ? '#000' : '#F03C18' }">{{ i.num
              }}</span>
            </p>
            <p class="f24 mt20">{{ i.dateTime }}</p>
          </div>
        </div>
      </template>
      <div class="empty-box" v-else>
        <img :src="baseImgUrl + 'empty.png'" alt="">
        <p>您还未获得领取机会哦</p>
      </div>
    </van-popup>
    <!-- 电子徽章弹窗 -->
    <van-popup v-model="ebadgeShow" class="e-badge-pop"
      :overlay-style="{ background: 'rgba(0, 0, 0, 0.85)', 'backdrop-filter': 'blur(10px)' }">
      <img class="e-close" :src="baseImgUrl + 'close3.png'" alt="" @click="ebadgeShow = false">
      <img class="e-img" :src="eBadgeItem.imgUrl" alt="">
      <p class="f48 fw6 mt46">{{ eBadgeItem.name }}</p>
      <p class="mt50">{{ eBadgeItem.copywriting }}</p>
      <p class="e-p "><span></span><span>完成进度 {{ eBadgeItem.curProgress }}/{{ eBadgeItem.target }}</span><span></span></p>
      <template v-if="!activityEnd">
        <template v-if="!eBadgeItem.isCompleted">
          <p>活动时间：{{ actTime }}</p>
          <img class="e-show-btn" :src="baseImgUrl + 'e-show-btn' + (eBadgeItem.index == 2 ? '3' : '1') + '.png'"
            @click="jumpWhere">
          <p class="mt30">完成任务后将自动发放至账户中</p>
        </template>
        <template v-else>
          <p>徽章已发放至您的账户中</p>
          <img class="e-show-btn" @click="getEbadgeDetail" :src="baseImgUrl + 'e-show-btn2.png'">
        </template>
      </template>
      <img v-else class="e-show-btn" @click="ebadgeShow = false" :src="baseImgUrl + 'gameover.png'">
    </van-popup>
  </div>
</template>
<script>
import { newAppBack, initBack, gotoAppPage } from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'
import commonShare from '@/components/commonShare/newShare.vue'
import Mission from './components/mission.vue';
import inviteGift from '@/views/invite-gift/index'
import userMixin from '@/mixin/userMixin'
import headerMixin from '@/mixin/headerMixin'
import wx from 'weixin-js-sdk'
const webUrl = `${window.location.origin}/#/superdeer-activity/88buy-preheat-2024/index`

export default {
  components: {
    navBar,
    commonShare,
    Mission,
    inviteGift
  },
  mixins: [userMixin, headerMixin],
  data() {
    return {
      baseImgUrl: 'https://img.chaolu.com.cn/ACT/88buy-preheat-2024/',
      barHeight: 0,
      showShare: false,
      ruleShow: false,
      userDetail: {},
      shareParams: {
        title: '超鹿邀你抽好礼，100%中奖',
        miniImage: 'https://img.chaolu.com.cn/ACT/88buy-preheat-2024/mini-share.png',
        path: `/pages/webView/index?webUrl=${webUrl}`,
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
      activityEnd: false,
      tabIndex: 1,
      tabHeight: 0,
      tabNum: { drawNum: 0, notEnterNum: 0, unCheckInNum: 0 },
      tabPointList: ['', '40019', '40020', '40021'],
      // 团课打卡
      activityNo: '',
      thisWeekNum: 0,
      teamDetailShow: false,
      teamDetailList: [],
      // 团课徽章
      rewardInfo: [],
      btnText: { 'NO_STOCK': '无库存', 'OVER': '已结束', 'NOT_FINISH': '未达成', 'NOT_RECEIVE': '领取', 'RECEIVED': '查看' },
      receiveTime: 0,
      getNum: 0,
      getItem: {},
      badgeGetShow: false,
      badgeListShow: false,
      limitedRewardInfo: {},
      checkBadgeItme: {},
      getBadgeDetailShow: false,
      getBadgeListShow: false,
      badgeList: [],
      // tab1中的徽章
      ebadgeShow: false,
      eBadgeList: [],
      limitedReward: {},
      eBadgeItem: {},
      actTime: ''
    }
  },
  computed: {
  },
  async created() {
    initBack();
    await this.$getAllInfo(['userId', 'venueId', 'cityId'])
    this.init()
    const uu = encodeURIComponent(`${webUrl}`)
    this.shareParams.path = `/pages/webView/index?webUrl=${uu}`
    if (this.appTypeStr === "mini") {
      wx.miniProgram.postMessage({
        data: {
          type: "share",
          shareImage: this.shareParams.miniImage,
          shareTitle: this.shareParams.title,
          shareUrl: this.shareParams.path,
        },
      });
      this.countPoint('403', '403', '40018')
    } else {
      this.countPoint('403', '403', '40017')
    }
  },
  mounted() {
  },
  methods: {
    newAppBack,
    gotoAppPage,
    setBarHeight(h) {
      this.barHeight = h || 0
    },
    tabChange(v) {
      this.countPoint('403', '403', this.tabPointList[v])
      if (this.activityEnd && v == 3) {
        this.$router.push({ path: '/invite-gift', query: { userId: 1 } })
      }
      this.tabIndex = v
      this.$nextTick(() => {
        let topHeight = this.$refs.topRef ? this.$refs.topRef.offsetHeight : 0
        window.scrollTo({
          left: 0,
          top: topHeight - this.barHeight - 55,
          behavior: 'smooth',
        })
      })

    },
    init() {
      this.getTabNum()
      this.actDteail()
      this.getEBadgeDetail()
      this.initQr()
    },
    getTabNum() {
      this.$axios.post(`${this.baseURLApp}/anniversary/taskGift/queryNumOnTab`, { userId: this.userId }).then((res) => {
        this.tabNum = res.data
      })
    },
    actDteail() {
      this.$axios.post(`${this.baseURLApp}/anniversary/checkin/schedule/info`, { userId: this.userId }).then((res) => {
        if (res.data.closeWeb) {
          this.gameoverBack()
        } else {
          const { thisWeekNum, rewardInfo, receiveTime, limitedRewardInfo, activityNo, activityEnd } = res.data
          this.thisWeekNum = thisWeekNum
          this.receiveTime = receiveTime
          this.rewardInfo = rewardInfo
          this.activityNo = activityNo
          this.activityEnd = activityEnd
          let num = rewardInfo.filter(i => i.rewardStatus == 'RECEIVED')
          this.getNum = 4 - num.length
          this.limitedRewardInfo = limitedRewardInfo
        }
      })
    },
    gameoverBack() {
      this.$dialog.alert({
        message: ' <b>很抱歉，该活动已经结束！<br/>去看看超鹿其他的活动吧</b>',
        confirmButtonText: '我知道了',
        className: 'dialog',
        overlayStyle: { backgroundColor: 'rgba(0,0,0,1)' }
      }).then(() => {
        if (this.appTypeStr === 'mini') {
          wx.miniProgram.switchTab({
            url: `/pages/home/index`
          })
        } else {
          this.newAppBack()
        }
      })
    },
    getTeamDetail() {
      this.$axios.post(`${this.baseURLApp}/anniversary/checkin/schedule/listEffectiveSchedule`,
        { userId: this.userId }
      ).then((res) => {
        this.teamDetailList = res.data
        this.teamDetailShow = true
      })
    },
    getSmallBadge(item) {
      if (item.rewardStatus == 'NOT_RECEIVE') {
        this.getItem = item
        this.badgeGetShow = true
      } else if (item.rewardStatus == 'RECEIVED') {
        this.checkBadgeItme = item
        this.getBadgeDetailShow = true
      } else {
        let t = this.activityEnd ? '活动已结束' : '暂无领取机会~'
        this.$toast(t)
      }
    },
    confirmBadge() {
      this.$axios.post(`${this.baseURLApp}/anniversary/checkin/schedule/receive`,
        { userId: this.userId, rewardId: this.getItem.rewardId }
      ).then((res) => {
        this.$toast('领取成功~')
        this.badgeGetShow = false
        this.actDteail()
      })
    },
    getBigBadge() {
      if (this.limitedRewardInfo.rewardStatus == 'NOT_RECEIVE') {
        this.$router.push({
          path: '/superdeer-activity/88buy-preheat-2024/receive', query: {
            activityNo: this.activityNo
          }
        })
      } else if (this.limitedRewardInfo.rewardStatus == 'RECEIVED') {
        this.checkBadgeItme = { ...this.limitedRewardInfo, isBig: true }
        this.getBadgeDetailShow = true
      }
    },
    getBadgeListFun() {
      this.$axios.post(`${this.baseURLApp}/anniversary/checkin/schedule/getReceiveChanceFlow`,
        { userId: this.userId }
      ).then((res) => {
        this.badgeList = res.data
        this.getBadgeListShow = true
      })
    },
    getEBadgeDetail() {
      this.$axios.post(`${this.baseURLApp}/anniversary/taskGift/queryMedals`,
        { userId: this.userId }
      ).then((res) => {
        this.eBadgeList = res.data.infos.map((i, index) => ({ ...i, index }))
        this.limitedReward = res.data.limitedRewardInfoVO
        this.limitedReward.activityNo = res.data.activityId
        this.actTime = res.data.startTime.slice(0, 10) + '-' + res.data.endTime.slice(0, 10)
      })
    },
    showEbadge(i) {
      this.eBadgeItem = i
      this.ebadgeShow = true
    },
    getEbadgeDetail() {
      this.getBadgeDetailShow = true
      this.checkBadgeItme = { rewardImg: this.eBadgeItem.imgUrl, rewardName: this.eBadgeItem.name }
      this.ebadgeShow = false
    },
    jumpWhere(type) {
      if (type == 'team') {
        this.countPoint('403', '403', '40021')
        this.gotoAppPage(3)
        return false
      }
      const { index } = this.eBadgeItem
      if (index == 0) {
        this.gotoAppPage(1, '')
      } else if (index == 1) {
        this.gotoAppPage(8)
      } else if (index == 3) {
        this.showShare = true
      }
      this.ebadgeShow = false
    },
    getEbigBadge() {
      this.$router.push({
        path: '/superdeer-activity/88buy-preheat-2024/receive', query: {
          activityNo: this.limitedReward.activityNo
        }
      })
    },
    eBadgeDetailShow() {
      this.getBadgeDetailShow = true
      this.checkBadgeItme = { ...this.limitedReward, isBig: true }
    },
    initQr() {
      this.$axios.post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
        token: this.token,
        userId: this.userId,
        width: 240,
        height: 240,
        webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
        scene: JSON.stringify({
          webUrl,
          source: `88buy-preheat-2024`,
          userId: 1,
        }),
      }).then((res) => {
        this.userDetail = res.data
      });
    },
    showShareFun() {
      this.showShare = true
      if (this.appTypeStr == 'mini') {
        this.shareSuccessFun(true)
      }
    },
    shareSuccessFun(type) {
      this.$axios.post(`${this.baseURLApp}/anniversary/taskGift/shareLink`,
        { userId: this.userId }
      ).then((res) => {
        this.$refs.missRef.getData()
        this.$refs.missRef.getDrawNum()
        this.getEBadgeDetail()
        this.getTabNum()
        if (!type) {
          this.showShare = false
        }
      })
    },
    scrollDownFun() {
      window.scrollTo({
        left: 0,
        top: 1000000,
        behavior: 'smooth',
      })
    },
  },
}
</script>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}

/deep/.van-popup__close-icon--top-right {
  top: 52px;
}


@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


.content {
  background-color: #ABE2FF;
  position: relative;

  .empty-box {
    color: #6C727A;
    font-size: 28px;
    text-align: center;
    height: 800px;
    padding-top: 10px;
    font-weight: 400;

    img {
      width: 320px;
      height: 320px;
      margin-bottom: 20px;
    }
  }

  .gray {
    filter: grayscale(100%);
  }

  .top-box {
    position: relative;

    .top {
      width: 100%;
    }

    .top-btn {
      position: absolute;
      width: 536px;
      left: 50%;
      transform: translateX(-50%);
      top: 514px;
    }

    .rs {
      position: absolute;
      right: 0;
      top: 230px;
      width: 88px;
      z-index: 99;

      .sec1,
      .sec2 {
        line-height: 56px;
        background: rgba(0, 0, 0, 0.3);
        border-top-left-radius: 28px;
        border-bottom-left-radius: 28px;
        color: #fff;
        width: 100%;
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 32px;
        padding-left: 6px;
      }
    }

    .arrow {
      width: 28px;
      height: 25px;
      position: absolute;
      bottom: 120px;
      left: 50%;
      transform: translateX(-50%);
      -webkit-animation: scale-up-center 2s linear infinite alternate both;
      animation: scale-up-center 2s linear infinite alternate both;
    }
  }

  .tab-box {
    background: #ABE2FF url(https://img.chaolu.com.cn/ACT/88buy-preheat-2024/tab-bg.png)top/100% auto no-repeat;
    margin-top: -100px;
    z-index: 10;
    position: sticky;
    bottom: 0;
    text-align: center;
    border-radius: 50px 50px 0px 0px;
    display: flex;
    justify-content: space-between;
    padding: 48px 15px 34px;
    font-size: 0px;

    >div {
      font-size: 28px;
      width: 240px;
      height: 116px;
      box-sizing: border-box;
      border: 12px solid #ABE2FF;
      color: #004E88;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: linear-gradient(0deg, #78C4FF 0%, #BCFFF5 100%);
      border-radius: 100px;
      position: relative;

      &>.line1 {
        font-family: PuHuiTi;
        display: inline-block;
      }

      &>.line2 {
        font-size: 22px;
        display: inline-block;
      }

      &.act {
        background: url(https://img.chaolu.com.cn/ACT/88buy-preheat-2024/tab-act.png)top/100% auto no-repeat;
        border: 0px;
        color: #fff;
        text-shadow: 0px 0px 4px rgba(205, 16, 0, 0.7);

        &>.line1 {
          font-size: 32px;
        }

        &>.line2 {
          font-family: PuHuiTi;
        }

        &>.tips {
          top: -37px;
          left: 85px;
        }

        &:last-child>.tips {
          left: 44px;
        }
      }

      .tips {
        position: absolute;
        font-size: 22px;
        background-color: #FD5900;
        color: #fff;
        padding: 5px 12px;
        border-radius: 30px;
        top: -49px;
        left: 73px;

        &::before {
          content: '';
          display: inline-block;
          background: url(https://img.chaolu.com.cn/ACT/88buy-preheat-2024/triangle.png)top/100% auto no-repeat;
          width: 13px;
          height: 8px;
          position: absolute;
          top: 35px;
          left: 77px;
        }

      }

      &:last-child {
        .tips {
          left: 32px;
        }
      }
    }

  }

  .content-box {
    >div {
      // padding: 0px 32px;
      padding-bottom: calc(constant(safe-area-inset-bottom) + 100px);
      padding-bottom: calc(env(safe-area-inset-bottom) + 100px);
    }

    .o6 {
      opacity: 0.6
    }

    .e-badge-box {
      width: 733px;
      height: 1252px;
      background: url(https://img.chaolu.com.cn/ACT/88buy-preheat-2024/e-badge-bg.png)top/100% auto no-repeat;
      padding: 220px 54px 49px;
      margin: 65px auto 0px;
      text-align: center;

      .e-tips {
        font-size: 22px;
        color: #42637F;
        margin-bottom: 182px;
      }

      .e-badges {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        text-align: center;
        margin-bottom: 25px;

        div {
          font-size: 0px;

          img {
            width: 140px;
            height: 140px;
          }

          .l2 {
            font-size: 22px;
            color: #222;
            margin-top: 14px;
            margin-bottom: 12px;
          }

          .l3 {
            font-size: 20px;
            line-height: 1;
            color: #fff;
            background-color: #6BC9FF;
            border-radius: 8px;
            padding: 6px 15px;
            display: inline-block;
          }
        }
      }

      .big-badge {
        width: 250px;
        height: 250px;
        margin: 10px auto;
      }

      .eb-btns {
        text-align: center;

        img {
          width: 607px;
          height: 115px;
        }
      }

    }

    .team-title {
      width: 474px;
      height: 69px;
      margin: 0 auto 40px;
      text-align: center;
    }

    .team-box {
      width: 686px;
      height: 575px;
      background: url(https://img.chaolu.com.cn/ACT/88buy-preheat-2024/team-bg.png)top/100% auto no-repeat;
      padding: 44px 40px;
      margin: 0 auto;

      .detail {
        font-size: 24px;
        text-align: right;
        padding: 6px 0px 60px;
        font-weight: 600;

        i {
          margin-left: 4px;
        }
      }

      .l1 {
        text-align: center;
        font-size: 32px;
        font-family: PuHuiTi;
      }

      .l2 {
        font-size: 22px;
        text-align: center;
        margin-top: 14px;
      }

      .progress {
        .p {
          width: 586px;
          height: 14px;
          margin: 42px auto 92px;
          position: relative;

          .p-bg {
            position: absolute;
            height: 100%;
            width: 560px;
            background-color: #CBE8FF;
            left: 10px;
          }

          .p-color {
            position: absolute;
            height: 100%;
            background-color: #3BAAFF;
            width: 96%;
            left: 10px;
          }

          .points {
            display: flex;
            justify-content: space-between;

            >div {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 22px;
              z-index: 9;
              margin-top: -8px;

            }

            .point {
              width: 30px;
              height: 30px;
              margin-bottom: 11px;
            }

          }
        }
      }

      .l3 {
        font-size: 24px;
        color: #FD5900;
        display: flex;
        justify-content: center;

        span>span {
          background-color: #FD5900;
          color: #fff;
          padding: 5px 10px;
          border-radius: 8px;
          margin-left: 7px;
        }
      }

      .p-btn {
        width: 568px;
        height: 90px;
        background: url(https://img.chaolu.com.cn/ACT/88buy-preheat-2024/p-btn.png?v=1)top/100% auto no-repeat;
        margin: 35px auto 0;
      }
    }

    .badge-box {
      width: 686px;
      height: 829px;
      background: url(https://img.chaolu.com.cn/ACT/88buy-preheat-2024/badge-bg2.png)top/100% auto no-repeat;
      padding: 54px 30px 44px;
      margin: 48px auto 0px;

      .can {
        color: #0A88E9;
        margin-right: 28px;
      }

      .b-tips {
        font-size: 22px;
      }

      .bs {
        display: flex;
        justify-content: space-between;

        >div {
          width: 140px;
          text-align: center;
          font-size: 24px;
          color: #010101;
          font-weight: 600;

          img {
            width: 140px;
            height: 140px;
            margin-bottom: 20px;
          }

          .b-btn {
            width: 140px;
            height: 52px;
            line-height: 52px;
            border-radius: 8px;
            text-align: center;
            color: #fff;
            background-color: #3BAAFF;
            margin-top: 22px;

            &.get {
              opacity: 0.6;
            }
          }
        }
      }

      .l2 {
        font-size: 24px;
        text-align: center;
        color: #FD5900;
        margin-top: 46px;

        span>span {
          background-color: #FD5900;
          color: #fff;
          padding: 7px 10px;
          border-radius: 8px;
          margin-left: 7px;
        }
      }

      .big-badge {
        background: linear-gradient(to bottom, #FC686F, #FF934C);
        width: 626px;
        height: 168px;
        margin-top: 30px;
        border-radius: 20px;
        padding: 4px;

        .box {
          width: 618px;
          height: 160px;
          background-color: #FFF3EC;
          border-radius: 18px;
          padding: 20px;
          display: flex;

          .badge-img {
            width: 120px;
            height: 120px;
            margin-right: 30px;
          }

          .b-l2 {
            font-size: 20px;
            line-height: 1;
            color: #fff;
            margin: 10px 0px 12px;

            span {
              padding: 7px 10px;
              border-radius: 4px;
              background-color: #FF7C2F;
              display: inline-block;

              &+span {
                margin-left: 7px;
              }
            }
          }

          .big-btn {
            width: 120px;
            height: 120px;
            background: url(https://img.chaolu.com.cn/ACT/88buy-preheat-2024/badge-btn.png)top/100% auto no-repeat;
            font-size: 26px;
            line-height: 120px;
            text-align: center;
            color: #fff;
            font-family: PuHuiTi;

            &.get {
              background: url(https://img.chaolu.com.cn/ACT/88buy-preheat-2024/get-badge-btn.png)top/100% auto no-repeat;
            }
          }
        }
      }
    }
  }
}

.pop {
  .title {
    height: 116px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(104, 201, 233, 0.3) 100%);
    border-radius: 24px 24px 0px 0px;
    text-align: center;
    padding-top: 46px;
    position: relative;

    >span {
      font-size: 36px;
      padding: 0px 7px;
      font-weight: 600;
      z-index: 1000;
      background-image: url(https://img.chaolu.com.cn/ACT/88buy-preheat-2024/title-bg.png?v=1);
      background-size: 160px 18px;
      background-repeat: no-repeat;
      background-position: left bottom;
    }
  }

  .tips {
    font-size: 24px;
    color: #666;
    text-align: center;
    margin-top: 6px;
    padding-bottom: 10px;
  }

  .gray-line {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
    height: 16px;
    background-color: #F5F5F5;
  }

  .empty-box {
    color: #6C727A;
    font-size: 28px;
    text-align: center;
    height: 800px;
    padding-top: 180px;
    font-weight: 400;

    img {
      width: 320px;
      height: 320px;
      margin-bottom: 20px;
    }
  }

  &.team-detail,
  &.get-badge-list {
    .pop-c {
      height: 800px;
      overflow: scroll;
      padding: 22px 32px 32px;

      div {
        border-bottom: 1px solid #ECECEC;
        margin-bottom: 40px;
        padding-bottom: 40px;
      }
    }
  }

  &.badge-detail {
    font-size: 28px;
    text-align: center;
    height: 800px;

    .b-detail {
      display: flex;
      padding: 0px 32px;
      text-align: left;

      img {
        width: 160px;
        height: 160px;
        margin-right: 40px;
      }
    }
  }

  &.rule-pop {
    .rule-box {
      padding: 29px 52px;
      font-size: 26px;
      font-weight: 400px;
      height: 900px;
      overflow: scroll;

      >p+p {
        margin-top: 30px;
      }
    }
  }

}

.get-pop {
  background-color: transparent;
  height: 100vh;
  padding-top: 105px;
  text-align: center;
  color: #fff;

  .b-img {
    width: 750px;
    height: 750px;
  }

  .g-name {
    font-size: 40px;
    font-family: PuHuiTi;
    font-weight: 400;
    padding: 24px;
  }

  .btn {
    width: 500px;
    height: 90px;
    margin-top: 55px;
  }

  .g-close {
    width: 60px;
    height: 60px;
    margin-top: 130px;
  }
}

.e-badge-pop {
  background: transparent url(https://img.chaolu.com.cn/ACT/88buy-preheat-2024/light.png)top/100% auto no-repeat;
  height: 100vh;
  width: 100%;
  font-size: 24px;
  color: #fff;
  text-align: center;

  .e-close {
    position: absolute;
    width: 36px;
    height: 36px;
    top: 114px;
    left: 36px;
  }

  .e-img {
    width: 400px;
    height: 400px;
    margin-top: 257px;
  }

  .e-p {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 40px;
    font-weight: 400;

    >:first-child,
    >:last-child {
      display: inline-block;
      width: 120px;
      height: 2px;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
    }

    >:first-child {
      margin-right: 23px;
    }

    >:last-child {
      margin-left: 23px;
      background: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
    }

  }

  .e-show-btn {
    width: 400px;
    height: 90px;
    margin-top: 50px;
  }
}



@bei: 1px;

.canvascss {
  position: fixed;
  bottom: -200%;
  // top: 0px;
  width: @bei*654;
  height: @bei*1160;
  margin: 0 auto;
  box-sizing: border-box;

  .canvascss_bg {
    width: 100%;
    height: 100%;
  }

  .canvascss_left {
    position: absolute;
    left: @bei*32;
    bottom: @bei*36;
    z-index: 1;
    width: @bei*100;
    height: @bei*100;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .canvascss_name {
    position: absolute;
    left: @bei*160;
    bottom: @bei*122;
    z-index: 1;
    color: #242831;
    font-weight: bold;
    font-size: @bei*28;
    width: @bei*300;
  }

  .canvascss_right {
    position: absolute;
    right: @bei*32;
    bottom: @bei*34;
    width: @bei*120;
    height: @bei*120;
    border-radius: @bei*14;
    overflow: hidden;
    padding: @bei*8;
    box-sizing: border-box;
    background-color: #1F7BEF;

    img {
      width: @bei*104;
      height: @bei*104;
      border-radius: @bei*6;
    }
  }
}</style>
